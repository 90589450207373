exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carrito-tsx": () => import("./../../../src/pages/carrito.tsx" /* webpackChunkName: "component---src-pages-carrito-tsx" */),
  "component---src-pages-detalle-tsx": () => import("./../../../src/pages/detalle.tsx" /* webpackChunkName: "component---src-pages-detalle-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-productos-tsx": () => import("./../../../src/pages/productos.tsx" /* webpackChunkName: "component---src-pages-productos-tsx" */),
  "component---src-pages-rastrear-pedido-tsx": () => import("./../../../src/pages/rastrear-pedido.tsx" /* webpackChunkName: "component---src-pages-rastrear-pedido-tsx" */)
}

